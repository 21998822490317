<template>
  <screen_width_cont>
    <div class="politicas_privaci_cont ">
      <div class="cont_300_basico ancho_acotado">
        <div class="header_top cont_300N">
          <headerCerrar @cancelar="backLocal" colorIcon="rojoOscuro" />
        </div>

        <div class="politicas_privaci_modModalTitle title">
          {{ t("app.politica_privac_v2.tit") }}
        </div>

        <div class="politicas_privaci_modModalText">
          <politicas_privacidad />
        </div>
        <div class="politicas_privaci_mod_btn">
          <btnAction
            :textBtn="t('app.comunes.btns.aceptar')"
            :disabled="false"
            @action="aceptar_polit_privacidad"
            colorBack="azul"
            colorText="blco"
            estadoBtn="active"
          />
        </div>
      </div>

      <!-- </div> -->
    </div>
  </screen_width_cont>
</template>


<script>
// import { modalController } from "@ionic/vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";
import politicas_privacidad from "../components/centro_ayuda/politicas_privacidad.vue";
import btnAction from "../components/generales/btnAction.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import { mapMutations } from "vuex";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "term_condi",
  components: {
    headerCerrar,
    politicas_privacidad,
    btnAction,
    screen_width_cont,
  },
  methods: {
    ...mapMutations(["set_accept_poli_priv", "setshowSpinner"]),

    backLocal() {
      this.$router.push({ name: "registroDisclaimer", replace: true });
    },
    aceptar_polit_privacidad() {
      this.setshowSpinner(false);
      this.set_accept_poli_priv(true);
      this.$router.push({ name: "registroDisclaimer", replace: true });
    },
  },
};
</script>

<style>
.politicas_privaci_cont {
  padding-bottom: 4rem;
  transition: all 1s easy;
}
.politicas_privaci_mod {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */
  padding: 0 1.68rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.politicas_privaci_modModal {
  display: block;
  background-color: #ffffff;
  border-radius: 20px;
  /* width: 85%;
  max-width: 85%; */
  /* margin: 15% auto auto auto; */

  width: 19.125rem;
  height: fit-content;
  /* margin: 15% 7% auto 7%; */

  padding: 1.5rem;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
}
.politicas_privaci_modModalTitle {
  color: var(--customA-801B1C);
  text-align: center;
  padding-top: 1.5rem;
}
.politicas_privaci_modModalText {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--customG-787878);
  text-align: justify;
  padding: 0 1rem;
}
.politicas_privaci_mod_btn {
  margin-top: 1.9375rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  padding-bottom: 4rem;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .politicas_privaci_mod {
    width: 60%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>